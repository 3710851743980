import React from 'react'

import MyPage from '../components/my_page'

const NotFoundPage = () => {
  const pageTitle = '404: Nerasta'
  const pageContent = <p>Tokio puslapio nėra! Pėdutės neranda kelio...</p>

  return (
    <MyPage pageTitle={pageTitle} pageContent={pageContent} pageType="simple" />
  )
}

export default NotFoundPage
